import React from "react";
import { Input, InputGroup, Label } from "reactstrap";

const TextInput = ({ label, icon, ...rest }) => {
    return (
        <InputGroup>
            <Label className='text-label w-100'>
                {label}
                <Input className='w-100 mt-1' {...rest} />
            </Label>
        </InputGroup>
    )
}

export default TextInput;