import React from "react";
import Swal from "sweetalert2";
import { Link, useHistory } from "react-router-dom";
import { Col, Input, Row } from "reactstrap";

import useSelectedProducts from "providers/selectedProducts";
import useLoading from "providers/loading";
import api from "config/api";
import swalError from "common/swalError";
import ModalEditRule from "../../ModalEditRule";

import "./styles.scss";
export default function TableLineProduct({
  lastProductElementRef,
  withDeleteButton,
  excludedFields,
  product,
  isGlobalProducts,
  setProduct,
  setModalVariantsOpen,
  withEditButton,
  reset
}) {
  const history = useHistory();
  const { setIsLoading } = useLoading();
  const { removeProduct } = useSelectedProducts();
  const [modalEditRuleOpen, setModalEditRuleOpen] = React.useState(false);

  const excludeField = (field) => excludedFields?.includes(field);

  const concanedVariants = (variants) => {
    return variants?.map(({ values }) => (
      values.map(({ pt }) => pt)
    )).join(' - ') || ""
  }

  const addProduct = (newProduct) => {
    const formattedProduct = {
      id: newProduct.id,
      name: newProduct.name,
      canonical_url: newProduct.canonical_url,
      images: product.images,
      variants: newProduct.variants
    };

    setProduct(formattedProduct);
    setModalVariantsOpen(true);
  }

  const deleteRule = async (ruleId) => {
    await api.nuvem.delete(`rule/${ruleId}`);
  }

  const deleteProduct = async (productId) => {
    try {
      Swal.fire({
        icon: 'info',
        title: 'Atenção',
        text: isGlobalProducts ? 'Deseja realmente excluir este produto da regra?' : 'Deseja realmente excluir a regra?',
        showCancelButton: true,
        cancelButtonText: 'Não',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim',
      }).then(async res => {
        if (res.value) {
          setIsLoading(true);
          await api.nuvem.delete(`product/${productId}`);

          if (!isGlobalProducts) {
            deleteRule(product.rule_id);
          }
          removeProduct(productId);

          Swal.fire({
            icon: 'success',
            title: 'Sucesso',
            text: isGlobalProducts ? 'Produto excluido da regra com sucesso' : 'Regra excluída com sucesso'
          });
          setIsLoading(false);
        }
      })
    } catch (error) {
      swalError(history, error);
    }
  }
  return (
    <>
      {
        modalEditRuleOpen && (
          <ModalEditRule
            isOpen={modalEditRuleOpen}
            toggle={() => setModalEditRuleOpen(!modalEditRuleOpen)}
            min={product.min}
            max={product.max}
            ruleId={product.rule_id}
            reset={reset}
          />
        )
      }
      <tr ref={lastProductElementRef}>
        <td className="TableTd">
          <div>
            <img
              className="product-img"
              src={
                product.images?.length > 0
                  ? product.images[0].src
                  : (product.img === '/' || !product.img ? require("../../../../../assets/img/brand/no-image.png") : product.img)
              }
              alt="Imagem do produto"
            />
          </div>
        </td>
        <td className="TableTd text-left">{product.name?.pt || product.name}</td>
        {
          (!isGlobalProducts && withDeleteButton) && (
            <td>
              <Row form className='container-input mx-auto'>
                <Col>
                  <Input
                    name='min'
                    id='min'
                    value={product.min}
                    disabled
                  />
                </Col>
                <Col>
                  <Input
                    name='max'
                    id='max'
                    value={product.max}
                    disabled
                  />
                </Col>
              </Row>
            </td>
          )
        }
        {
          !excludeField('variants') && (
            <td className='TableTd'>
              <p className="TextWrap">
                {
                  concanedVariants(product.variants) || product.variation_name || '-'
                }
              </p>
            </td>
          )
        }
        <td className="TableTd">
          <div className="table-action">
            <Link
              className="table-action-button-edit"
              to={{
                pathname: product.canonical_url || product.url
              }}
              target='_blank'
            >
              <i className="fas fa-eye" />
            </Link>
            {
              withEditButton && !isGlobalProducts && (
                <div
                  className="table-action-button-sucess"
                  onClick={() => setModalEditRuleOpen(true)}
                >
                  <i className="fas fa-edit" />
                </div>
              )
            }
            {withDeleteButton
              ? <div
                className="table-action-button-delete"
                onClick={() => deleteProduct(product.id)}
              >
                <i className="fas fa-trash" />
              </div>
              : <div
                className="table-action-button-sucess"
                onClick={() => addProduct(product)}
              >
                <i className="fas fa-arrow-right" />
              </div>
            }
          </div>
        </td>
      </tr>
    </>
  );
}
