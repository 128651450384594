import clsx from "clsx";
import React from "react";

import './styles.scss';

const MenuContext = React.createContext(null);

const MenuProvider = ({ children, initialActive }) => {
    const [menuActive, setMenuActive] = React.useState(0);

    const isActive = (identification) => menuActive === identification;

    React.useLayoutEffect(() => {
        setMenuActive(initialActive);
    }, [initialActive]);
    return (
        <MenuContext.Provider value={{ menuActive, setMenuActive, isActive }}>
            {children}
        </MenuContext.Provider>
    )
}

const MenuContainer = ({ children, initialActive }) => {
    return (
        <MenuProvider initialActive={initialActive}>
            {children}
        </MenuProvider>
    )
}

const ActiveMenu = ({ children, targetIdentification, onClick, disabled }) => {
    const { setMenuActive, isActive } = React.useContext(MenuContext);

    return (
        <div
            className={clsx({
                "menu-disabled": disabled
            })}
            onClick={(e) => {
                if (!disabled) {
                    setMenuActive(targetIdentification);

                    if (onClick) onClick(e);
                }
            }}
        >
            {React.cloneElement(children, { active: isActive(targetIdentification) })}
        </div>
    )
}

const MenuContent = ({ children, identification, tag: Component }) => {
    const { isActive } = React.useContext(MenuContext);
    const contentIsActive = isActive(identification);

    return (
        <Component className={clsx({
            "d-none": !contentIsActive,
            "d-block": contentIsActive,
        })}>
            {children}
        </Component>
    )
}

export default MenuContainer;
export { MenuContent, ActiveMenu };