import React from "react"

const SelectedProductsContext = React.createContext(null);

const SelectedProductsProvider = ({ children }) => {
    const [selectedProducts, setSelectedProducts] = React.useState([]);

    const resetSelectedProducts = () => setSelectedProducts([]);

    const removeProduct = (productId) => setSelectedProducts(prevState => prevState.filter(({ id }) => id !== productId));

    return (
        <SelectedProductsContext.Provider 
            value={{ 
                selectedProducts, 
                setSelectedProducts, 
                resetSelectedProducts,
                removeProduct
            }}
        >
            {children}
        </SelectedProductsContext.Provider>
    )
}

export { SelectedProductsProvider };

const useSelectedProducts = () => {
    const context = React.useContext(SelectedProductsContext);

    if (!context) {
        throw Error('Must be inside a SelectedProductsProvider');
    }

    return context;
}

export default useSelectedProducts;