import React from "react";
import IconBase from "@mdi/react";
import { mdiInformationOutline } from '@mdi/js';

const RuleForSpecificProducts = () => {
    return (
        <>
            <p className="mb-4 info-card d-flex align-items-center">
                <IconBase className="mr-2" size={0.8} path={mdiInformationOutline} />
                Selecione os produtos que você deseja adicionar uma regra.
            </p>
        </>
    )
}

export default RuleForSpecificProducts;