import React from "react";

import Page from "components/Page";
import FirstStep from "./FirstStep";
import SecondStep from "./SecondStep";
import { SelectedProductsProvider } from "providers/selectedProducts";

import "./styles.scss";
export default function Home() {
  const [firstStep, setFirstStep] = React.useState(true);
  const [activeRuleId, setActiveRuleId] = React.useState(false);
  const [ruleSelected, setRuleSelected] = React.useState(0);

  const nextStep = () => {
    setFirstStep(false);
  }
  const prevStep = () => {
    setFirstStep(true);
  }

  return (
    <SelectedProductsProvider>
      <Page title='Seja bem vindo ao Carrinho limitado'>
        {firstStep ?
          <FirstStep
            fnNextStep={nextStep}
            activeRuleId={activeRuleId}
            setActiveRuleId={setActiveRuleId}
            ruleSelected={ruleSelected}
            setRuleSelected={setRuleSelected}
          /> :
          <SecondStep
            ruleSelected={ruleSelected}
            prevStep={prevStep}
            activeRuleId={activeRuleId}
            setActiveRuleId={setActiveRuleId}
          />
        }
      </Page>
    </SelectedProductsProvider>
  );
}
