import React, { useRef, useCallback, useEffect } from "react";
import swalError from "common/swalError";
import { useHistory } from "react-router-dom";

import {
  Modal,
  Label,
  Input,
  Col,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  Table,
} from "reactstrap";

import api from "config/api";
import TableLineProduct from "./TableLineProduct";
import useLoading from "providers/loading";
import ModalSelectVariants from "views/admin/Home/ModalSelectVariants";

export default function ModalProductNuvemShop({
  isOpen,
  toggle,
  isGlobalProducts,
  activeRuleId,
  reset,
  title,
}) {
  const { setIsLoading, isLoading } = useLoading();
  const history = useHistory();

  const [modalVariantsOpen, setModalVariantsOpen] = React.useState(false);
  const [selectedProduct, setSelectedProduct] = React.useState({});

  const [productsNuvemShop, setProductsNuvemShop] = React.useState([]);
  const [search, setSearch] = React.useState("");
  const [page, setPage] = React.useState(1);
  const [hasMore, setHasMore] = React.useState(false);
  const limit = 10;

  const observer = useRef();
  const lastProductElementRef = useCallback(
    (node) => {
      if (isLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPage((page) => page + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [isLoading, hasMore]
  );

  const fetchProductsNuvemShop = React.useCallback(async (infiniteScroll) => {
    setIsLoading(true);
    try {
      const { data } = await api.nuvem.get(
        `/nuvem-products`, {
        params: {
          search
        }
      }
      );

      if (infiniteScroll) {
        setProductsNuvemShop((productsNuvemShop) => [
          ...productsNuvemShop,
          ...data,
        ]);
      } else {
        setProductsNuvemShop(data || []);
      }
      const total = data.pagination?.total;
      const totalPages = Math.ceil(total / limit);
      if (totalPages === page) setHasMore(false);
      else setHasMore(true);
    } catch (error) {
      swalError(history, error);
    }
    setIsLoading(false);
  }, [search, page, setIsLoading, history])

  const ifIsLastItem = (array, index) => array.length === index + 1;

  useEffect(() => {
    setPage(1);
    setProductsNuvemShop([]);
    setHasMore(false);
  }, [search]);

  return (
    <>
      {modalVariantsOpen &&
        <ModalSelectVariants
          isOpen={modalVariantsOpen}
          toggle={() => setModalVariantsOpen(!modalVariantsOpen)}
          product={selectedProduct}
          activeRuleId={activeRuleId}
          isGlobalProducts={isGlobalProducts}
          reset={reset}
        />
      }

      <Modal
        className="modal-dialog-centered modal-lg"
        isOpen={isOpen}
        toggle={() => toggle()}
      >
        <div className="modal-header">
          <h5 className="modal-title">{title}</h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => toggle()}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <Row>
            <Col className="col-12">
              <FormGroup>
                <Label className="newsale-label" for="inputProduct">
                  Inserir Produtos
                </Label>
                <InputGroup className="mb-1">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="fas fa-box-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    className="newsale-input"
                    type="text"
                    id="inputProduct"
                    placeholder="Digite o nome do produto aqui..."
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.keyCode === 13) {
                        fetchProductsNuvemShop(false);
                      }
                    }}
                  />
                  <InputGroupAddon addonType="append">
                    <InputGroupText
                      color="primary"
                      className="search-button btn btn-primary"
                      onClick={() => fetchProductsNuvemShop(false)}
                    >
                      Buscar
                    </InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <Table
                className="d-md-table text-center cart-adm-table"
                responsive
                striped
              >
                <thead>
                  <tr>
                    <th className="TableTh">Imagem</th>
                    <th className="TableTh text-left">Produto</th>
                    <th className="TableTh">Ações</th>
                  </tr>
                </thead>
                <tbody>
                  {productsNuvemShop?.length > 0 ? (
                    productsNuvemShop.map((product, index) =>
                    (
                      <TableLineProduct
                        key={product.id}
                        lastProductElementRef={
                          ifIsLastItem(productsNuvemShop, index) ?
                            lastProductElementRef :
                            null
                        }
                        setModalVariantsOpen={setModalVariantsOpen}
                        product={product}
                        excludedFields={['variants']}
                        setProduct={setSelectedProduct}
                      />
                    ))
                  ) : (
                    <tr>
                      <td colSpan="6">Nenhum registro encontrado...</td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Col>
          </Row>
        </div>
      </Modal>
    </>
  );
}
