import React from "react";
import { Col, Row, Table } from "reactstrap";

import api from "config/api";
import Button from "components/Button";
import useSelectedProducts from "providers/selectedProducts";
import RuleForGlobalProducts from "../RuleForGlobalProducts";
import RuleForSpecificProducts from "../RuleForSpecificProducts";
import TableLineProduct from "../ModalProductNuvemShop/TableLineProduct";
import { ruleOptions } from "variables/ruleOptions";
import ModalProductNuvemShop from "../ModalProductNuvemShop";
import useLoading from "providers/loading";
import Swal from "sweetalert2";
import CustomPagination from "components/CustomPagination";
import usePagination from "hooks/pagination";

const SecondStep = ({
    ruleSelected,
    prevStep,
    activeRuleId,
    setActiveRuleId
}) => {
    const LIMIT_PER_PAGE = 10;
    const OPTIONS_GLOBAL_PRODUCTS = [0, 1];
    const { selectedProducts, resetSelectedProducts, setSelectedProducts } = useSelectedProducts();
    const { currentItens, currentPage, setCurrentPage } = usePagination({
        limit: LIMIT_PER_PAGE,
        itens: selectedProducts,
    })
    const { setIsLoading } = useLoading();

    const [modaProductOpen, setModalProductOpen] = React.useState(false);
    const [minMaxActive, setMinMaxActive] = React.useState({
        min: 0,
        max: 0,
    })

    const isGlobalProducts = OPTIONS_GLOBAL_PRODUCTS.includes(ruleSelected);
    const storeId = localStorage.getItem('storeId');
    const titleOfTheRules = ruleOptions.filter(({ identification }) => ruleSelected === identification)[0].title;

    const handleClickArrow = () => {
        resetSelectedProducts();
        prevStep();
    }

    const getProducts = React.useCallback(async () => {
        setIsLoading(true);
        const { data } = await api.nuvem.get(`rules/${storeId}`);
        if (data.length > 0) {
            const { min, max } = data[0];
            const allProducts = data.map(({ Product: products }, i) => {
                if (isGlobalProducts) {
                    return products;
                } else {
                    return {
                        ...products[0],
                        rule_id: data[i].id,
                        min: data[i].min,
                        max: data[i].max
                    };
                }
            });
            setSelectedProducts(isGlobalProducts ? allProducts[0] : allProducts);

            setMinMaxActive({
                min,
                max
            });
        }
        setIsLoading(false);
        return;
    }, [setIsLoading, setSelectedProducts, storeId, isGlobalProducts]);

    React.useEffect(() => {
        getProducts();
    }, [getProducts]);

    return (
        <>
            {
                modaProductOpen &&
                <ModalProductNuvemShop
                    isOpen={modaProductOpen}
                    toggle={() => setModalProductOpen(!modaProductOpen)}
                    activeRuleId={activeRuleId}
                    isGlobalProducts={isGlobalProducts}
                    reset={getProducts}
                    title={isGlobalProducts ? 'Adicionar produto como execeção da regra' : 'Adicionar regra a um produto'}
                />
            }

            <span onClick={handleClickArrow} className='cursor-pointer d-flex mb-4 mt--2'>
                <i className="fas fa-arrow-left" />
            </span>

            <h2>{titleOfTheRules}</h2>

            {
                isGlobalProducts ?
                    <RuleForGlobalProducts
                        ruleSelected={ruleSelected}
                        activeRuleId={activeRuleId}
                        setActiveRuleId={setActiveRuleId}
                        minMaxActive={minMaxActive}
                        setMinMaxActive={setMinMaxActive}
                    /> :
                    <RuleForSpecificProducts />
            }
            <Row>
                <Col className='d-flex align-items-center'>
                    <Table
                        className="d-table text-center newsale-products-table"
                        responsive
                    >
                        <thead>
                            <tr>
                                <th className="TableTh">Imagem</th>
                                <th className="TableTh text-left">Produto</th>
                                {!isGlobalProducts && <th className="TableTh">Regra</th>}
                                <th className="TableTh">Variação</th>
                                <th className="TableTh">Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentItens.length > 0 ? (
                                currentItens.map((product) => (
                                    <TableLineProduct
                                        key={product.id}
                                        product={product}
                                        excludedFields={['price']}
                                        withDeleteButton
                                        withEditButton
                                        isGlobalProducts={isGlobalProducts}
                                        reset={getProducts}
                                    />
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="6">Nenhum produto foi adicionando...</td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </Col>
            </Row>
            {
                currentItens.length > 0 && (
                    <Row>
                        <Col className='d-flex justify-content-center mt-2'>
                            <CustomPagination
                                limit={LIMIT_PER_PAGE}
                                page={currentPage}
                                setPage={setCurrentPage}
                                total={selectedProducts.length}
                                length={currentItens.length}
                            />
                        </Col>
                    </Row>
                )
            }
            <Row className='mt-2r'>
                <Col className='d-flex justify-content-end'>
                    <Button
                        type='button'
                        onClick={() => {
                            if (isGlobalProducts && !activeRuleId) {
                                Swal.fire({
                                    icon: 'info',
                                    title: 'Atenção',
                                    text: 'Crie uma regra antes, para poder adicionar uma exceção',
                                });
                                return;
                            }
                            setModalProductOpen(true)
                        }}
                    >
                        {isGlobalProducts ? 'Adicionar produto na exceção' : 'Adicionar regra'}
                    </Button>
                </Col>
            </Row>
        </>
    )
}

export default SecondStep;