import { ReactComponent as CartIcon } from "assets/img/icons/cart.svg";
import { ReactComponent as BagIcon } from "assets/img/icons/bag.svg";

const ruleOptions = [
    {
        identification: 0,
        title: 'Carrinho Geral',
        description: 'Aqui você consegue configurar uma quantidade mínima e máxima de items no carrinho, para toda a loja. Além de ter a possibilidade de selecionar alguns produtos que não encaixam nesse limite configurado.',
        iconComponent: CartIcon
    },
    {
        identification: 1,
        title: 'Produto Geral',
        description: 'Aqui você consegue configurar uma quantidade mínima e máxima de items para todos os produtos da loja, para toda a loja. Além de ter a possibilidade de selecionar alguns produtos que não encaixam nesse limite configurado.',
        iconComponent: BagIcon
    },
    {
        identification: 2,
        title: 'Produto Individual',
        description: 'Nessa opção você consegue configurar a quantidade mínima e máxima de cada produto individual e suas respectivas variações.',
        iconComponent: BagIcon
    }
]

export { ruleOptions };