import React from "react";
import IconBase from "@mdi/react";
import { useHistory } from "react-router-dom";
import { mdiClose } from '@mdi/js';
import { Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";

import api from "config/api";
import useLoading from "providers/loading";
import swalError from 'common/swalError';
import Button from "components/Button";
import TextInput from "components/TextInput";
import Swal from "sweetalert2";

const ModalEditRule = ({
    isOpen,
    toggle,
    reset,
    ruleId,
    min: minOfItems,
    max: maxOfItems,
}) => {
    const history = useHistory();
    const { setIsLoading } = useLoading();
    const [min, setMin] = React.useState(minOfItems);
    const [max, setMax] = React.useState(maxOfItems);

    const editRule = async ({
        min,
        max,
        id
    }) => await api.nuvem.put('rule', {
        min,
        max,
        id
    });

    const handleClick = async () => {
        try {
            setIsLoading(true);
            await editRule({
                id: ruleId,
                min,
                max
            });

            reset();

            Swal.fire({
                icon: 'success',
                title: 'Sucesso!',
                text: 'Regra editada com sucesso!'
            });
        } catch (error) {
            swalError(history, error);
        } finally {
            setIsLoading(false);
        }
    }
    return (
        <Modal
            isOpen={isOpen}
            toggle={toggle}
            centered
            className="modal-container-md"
        >
            <ModalHeader className='modal-header'>
                <span className="d-flex justify-content-between align-items-center mb-0 h4">
                    Edite quantidade mínima e máxima da regra
                    <IconBase
                        path={mdiClose}
                        size={0.8}
                        className='close-icon cursor-pointer ml-2'
                        onClick={toggle}
                    />
                </span>
            </ModalHeader>
            <ModalBody>
                <Row>
                    <Col>
                        <TextInput
                            type='number'
                            id='min'
                            name='min'
                            value={min}
                            onChange={(e) => setMin(e.target.value)}
                            label='Mínimo'
                            placeholder="Mínimo de items"
                        />
                    </Col>
                </Row>
                <Row className='mb-3'>
                    <Col>
                        <TextInput
                            type='number'
                            id='max'
                            name='max'
                            value={max}
                            onChange={(e) => setMax(e.target.value)}
                            label='Máximo'
                            placeholder="Máximo de items"
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={4}>
                        <Button onClick={handleClick}>
                            Editar Regra
                        </Button>
                    </Col>
                </Row>
            </ModalBody>
        </Modal>
    )
}

export default ModalEditRule;