import React from "react";
import IconBase from "@mdi/react";
import Swal from "sweetalert2";
import { mdiInformationOutline } from '@mdi/js';
import { Col, Row } from "reactstrap";

import TextInput from "components/TextInput";
import Button from "components/Button";
import api from "config/api";
import useLoading from "providers/loading";

const RuleForGlobalProducts = ({
    ruleSelected,
    activeRuleId,
    setActiveRuleId,
    minMaxActive,
    setMinMaxActive
}) => {
    const { setIsLoading } = useLoading();
    const createRule = async ({
        min,
        max,
        typeRule
    }) => await api.nuvem.post('rule', {
        min,
        max,
        type: typeRule
    });

    const editRule = async ({
        min,
        max,
        id
    }) => await api.nuvem.put('rule', {
        min,
        max,
        id
    });

    const handleClick = async ({ min, max }) => {
        setIsLoading(true);
        
        if (activeRuleId) {
            await editRule({
                id: activeRuleId,
                min,
                max
            });
            Swal.fire({
                icon: 'success',
                title: 'Sucesso!',
                text: 'Regra editada com sucesso!'
            });
        } else {
            const { data } = await createRule({
                typeRule: ruleSelected,
                min,
                max
            });

            setActiveRuleId(data.id);
            Swal.fire({
                icon: 'success',
                title: 'Sucesso!',
                text: 'Regra criada com sucesso, agora você pode criar exceções'
            });
        }

        setIsLoading(false);
    }

    function setNewValue(event, setState = setMinMaxActive) {
        const { name, value } = event.target;

        setState(prevValue => {
            return {
                ...prevValue,
                [name]: value
            };
        });
    }
    return (
        <>
            <Row form className='w-50'>
                <Col>
                    <TextInput
                        label='Mínimo'
                        placeholder='Mínimo de produtos'
                        name='min'
                        id='min'
                        value={minMaxActive.min || 0}
                        onChange={e => setNewValue(e)}
                    />
                </Col>
                <Col>
                    <TextInput
                        label='Máximo'
                        placeholder='Máximo de produtos'
                        name='max'
                        id='max'
                        value={minMaxActive.max || 0}
                        onChange={e => setNewValue(e)}
                    />
                </Col>
            </Row>
            <p className="mb-4 d-flex align-items-center h5">
                Coloque 0 para não ter um mínimo ou um máximo de produtos no carrinho.
            </p>
            <Row form className='w-50 mb-4'>
                <Col>
                    <Button onClick={() => handleClick(minMaxActive)}>
                        {activeRuleId ? 'Editar regra' : 'Cria regra'}
                    </Button>
                </Col>
            </Row>
            <p className="mb-4 info-card d-flex align-items-center">
                <IconBase className="mr-2" size={0.8} path={mdiInformationOutline} />
                Selecione os produtos que você deseja excluir da regra selecionada.
            </p>
        </>
    )
}

export default RuleForGlobalProducts;