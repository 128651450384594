import clsx from "clsx";
import React from "react";

import './styles.scss';
const RuleCard = ({ text, active, iconComponent: Component }) => {
    return (
        <div className={clsx({
            "rule-card-container d-flex flex-column align-items-center rounded": true,
            "text-subtitle-secondary bg-rule-card-inactive": !active,
            "bg-rule-card-active": active
        })}>
            <div className={clsx({
                "mb-2": true,
                "text-eva": active,
            })}>
                <Component />
            </div>
            <p className={clsx({
                "text-capitalize": true,
                "text-subtitle": active,
            })}>
                {text}
            </p>
        </div>
    )
}

export default RuleCard;