import React from "react";
import Swal from "sweetalert2";
import { Col, Row } from "reactstrap";

import MenuContainer, { ActiveMenu, MenuContent } from "components/MenuContent";
import RuleCard from "components/RuleCard";
import Button from "components/Button";
import api from "config/api";
import useLoading from "providers/loading";
import { ruleOptions } from "variables/ruleOptions";
import { useHistory } from "react-router-dom";
import swalError from "common/swalError";

const FirstStep = ({
    fnNextStep,
    ruleSelected,
    setRuleSelected,
    activeRuleId,
    setActiveRuleId
}) => {
    const history = useHistory();
    const { setIsLoading } = useLoading();
    const id = localStorage.getItem('storeId');

    const [productsId, setProductsId] = React.useState([]);

    const handleDeleteClick = (ruleId) => {
        try {
            Swal.fire({
                icon: 'info',
                title: 'Atenção',
                text: 'Deseja realmente desativar a regra?',
                showCancelButton: true,
                cancelButtonText: 'Não',
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sim',
            }).then(async res => {
                if (res.value) {
                    setIsLoading(true);
                    const productsDelete = productsId.map((id) => api.nuvem.delete(`/product/${id}`));
                    await Promise.all(productsDelete).catch(error => swalError(history, error));
                    await deleteRule(ruleId);
                    setActiveRuleId(false);

                    setIsLoading(false);
                    Swal.fire({
                        icon: 'success',
                        title: 'Sucesso!',
                        text: 'Regra excluida com sucesso!'
                    });
                }
            })
        } catch (error) {
            swalError(history, error);
        }
    }

    const deleteRule = async (ruleId) => {
        const { data } = await api.nuvem.delete(`rule/${ruleId}`);

        return data;
    }

    const getActiveRule = async (storeId) => {
        const { data } = await api.nuvem.get(`rules/${storeId}`);

        return data;
    }

    React.useLayoutEffect(() => {
        const setActiveRule = async (storeId) => {
            try {
                setIsLoading(true);
                const data = await getActiveRule(storeId);
                const allProductsIds = [];
                if (data.length > 0) {
                    const { type, id, Product } = data[0];
                    Product.forEach(({ id }) => allProductsIds.push(id));
                    setRuleSelected(type);
                    setActiveRuleId(id);
                    setProductsId(prevState => prevState.concat(allProductsIds));
                    return;
                }
                return setActiveRuleId(false);
            } finally {
                setIsLoading(false);
            }
        }

        setActiveRule(id);
    }, [id, setIsLoading, setRuleSelected, setActiveRuleId])
    return (
        <Col>
            <p className="text-darker text-base mb-3">
                Por favor selecione a regra que você deseja aplicar  à sua loja
            </p>
            <MenuContainer initialActive={ruleSelected}>
                <section className='cards-container d-flex flex-wrap justify-content-start mb-2r'>
                    {ruleOptions.map(({ identification: targetIdentification, title, iconComponent }) => (
                        <ActiveMenu
                            key={targetIdentification}
                            targetIdentification={targetIdentification}
                            onClick={() => setRuleSelected(targetIdentification)}
                            disabled={activeRuleId && targetIdentification !== ruleSelected}
                        >
                            <RuleCard
                                iconComponent={iconComponent}
                                text={title}
                            />
                        </ActiveMenu>
                    ))}
                </section>
                {ruleOptions.map(({ identification, title, description }) => (
                    <MenuContent key={`rule-content-${identification}`} identification={identification} tag='section'>
                        <h3 className="text-darker text-base">{title}</h3>
                        <p className="text-darker m-0">{description}</p>
                    </MenuContent>
                ))}
            </MenuContainer>
            <Row className='mt-2r'>
                <Col className='d-flex flex-wrap justify-content-end gap-10'>
                    {
                        ruleSelected !== 2 && (
                            <Button background='error' className='mr-0' disabled={!activeRuleId} onClick={() => handleDeleteClick(activeRuleId)}>Desativar Regra</Button>
                        )
                    }
                    <Button onClick={fnNextStep}>Configurar regra</Button>
                </Col>
            </Row>
        </Col >
    )
}

export default FirstStep;