import React from "react";

const usePagination = ({
    itensPerPage = 10,
    itens = []
}) => {
    const [currentPage, setCurrentPage] = React.useState(1);

    const totalPages = Math.ceil(itens.length / itensPerPage);
    const startIndexItem = (currentPage - 1) * itensPerPage;
    const endIndexItem = startIndexItem + itensPerPage;
    const currentItens = itens.slice(startIndexItem, endIndexItem);

    return {
        totalPages,
        currentItens,
        currentPage,
        setCurrentPage,
    }
}

export default usePagination;