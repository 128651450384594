import clsx from "clsx";
import React from "react"
import { Button as ButtonReactstrap } from "reactstrap"

const Button = ({ children, className, outline, background = 'primary', ...rest }) => {
    return (
        <ButtonReactstrap
            className={clsx({
                [`bg-${background} text-white border-0`]: !outline,
                "border border-1 bg-transparent": outline,
                "btn-disabled": rest.disabled,
                [className]: !!className,
            })}
            {...rest}
        >
            {children}
        </ButtonReactstrap >
    )
}

export default Button;