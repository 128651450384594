import axios from "axios";

// Troque a baseURL pela rota da API desejada.
const api = {
  authenticate: axios.create({
    baseURL: "https://www.eva-system-api.plataformaeva.com/",
  }),
  nuvem: axios.create({
    baseURL: "https://www.min-max-app.plataformaeva.com"
  })
} 

const apiKeys = Object.keys(api);

apiKeys.forEach((key) => {
  api[key].interceptors.request.use((config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `bearer ${token}`;
    }
    return config;
  });
})

export default api;
