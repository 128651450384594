import React from "react";
import IconBase from "@mdi/react";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import { mdiClose, mdiInformationOutline } from '@mdi/js';
import { Col, Input, Modal, ModalBody, ModalHeader, Row, Table } from "reactstrap";

import api from "config/api";
import useLoading from "providers/loading";
import swalError from 'common/swalError';
import Button from "components/Button";

import './styles.scss';
import useSelectedProducts from "providers/selectedProducts";
const ModalSelectVariants = ({
    isOpen,
    product,
    toggle,
    isGlobalProducts,
    activeRuleId,
    reset
}) => {
    const history = useHistory();
    const { setIsLoading } = useLoading();
    const { selectedProducts } = useSelectedProducts();
    const [selectedVariants, setSelectedVariants] = React.useState(product.variants.map(variant => ({
        ...variant,
        min: 0,
        max: 0
    })));

    const findVariation = (variationId) => product.variants.filter(({ id }) => variationId === id)[0];
    const excludeVariation = (variationId) => selectedVariants.filter(({ id }) => id !== variationId);
    const ifAlreadySelected = (variatioId) => selectedVariants.find(({ id }) => variatioId === id);
    const ifIsChecked = (e) => e.target.checked;

    const addMin = (e, variationId) => {
        e.persist();
        const value = e.target.value;
        setSelectedVariants(prevState =>
            prevState.map((variation) =>
                variation.id === variationId
                    ? {
                        ...variation,
                        min: value
                    } : variation
            ))
    }

    const addMax = (e, variationId) => {
        e.persist();
        const value = e.target.value;
        setSelectedVariants(prevState =>
            prevState.map((variation) =>
                variation.id === variationId
                    ? {
                        ...variation,
                        max: value
                    } : variation
            ))
    }

    const selectNewVariation = (e, variationId) => {
        e.persist();
        if (ifIsChecked(e)) {
            const trElement = e.target.parentNode.parentNode;
            const inputMinValue = trElement.querySelector("#min").value;
            const inputMaxValue = trElement.querySelector("#max").value;

            return setSelectedVariants((prevState) => [...prevState, {
                ...findVariation(variationId),
                min: inputMinValue,
                max: inputMaxValue,
            }]);
        }
        return setSelectedVariants(excludeVariation(variationId));
    };

    const createRule = async ({
        min,
        max,
        typeRule
    }) => await api.nuvem.post('rule', {
        min,
        max,
        type: typeRule
    });

    const createRuleForProduct = async ({
        name,
        ruleId,
        url,
        variationId,
        variation_name,
        img
    }) => await api.nuvem.post('product', {
        name,
        ruleId,
        url,
        variationId,
        variation_name,
        img
    });

    const createRuleForGlobalProducts = async (variants, product) => {
        const allProductPromises = variants.map(({ id: variationId, values }) => {
            const newProduct = {
                name: product.name.pt,
                url: `${product.canonical_url}?/variant=${variationId}`,
                ruleId: activeRuleId,
                variationId,
                variation_name: values[0]?.pt || "-",
                img: product.images[0] ? product.images[0].src : '/',
                id: product.id,
            }

            return createRuleForProduct(newProduct);
        })
        await Promise.all(allProductPromises)
            .then(() => Swal.fire({
                icon: 'success',
                title: 'Sucesso!',
                text: 'Produto adicionado na exceção da regra',
            }))
            .catch(error => swalError(history, { ...error }));

        reset();
        return;
    }

    const createRuleForSpecifcProducts = async (variants, product) => {
        if (selectedVariants.length > 0) {
            const allProductPromises = variants.map(async ({ id: variationId, values, min, max }) => {
                const { data: ruleData } = await createRule({ min, max, typeRule: 2 });
                const newProduct = {
                    name: product.name.pt,
                    url: `${product.canonical_url}?/variant=${variationId}`,
                    ruleId: ruleData.id,
                    variationId,
                    variation_name: values[0]?.pt || "-",
                    img: product.images[0] ? product.images[0].src : '/'
                }

                return createRuleForProduct(newProduct);
            })

            await Promise.all(allProductPromises)
                .then(() => Swal.fire({
                    icon: 'success',
                    title: 'Sucesso!',
                    text: 'Regra criada com sucesso',
                }))
                .catch(error => swalError(history, { ...error }));
        } else {
            Swal.fire({
                icon: 'info',
                title: 'Atenção',
                text: 'Selecione um produto primeiro para continuar!'
            });
            return;
        }

        reset();
    }

    const isVariantAlreadyAdded = (products, variants) => {
        const variantsId = products.map(({ nuvemshop_variation_id }) => nuvemshop_variation_id);
        const areIncludes = variants.map(
            ({ id, values }) => variantsId.includes(id) ? {
                id,
                name: values[0].pt
            } : null
        )
        return areIncludes.filter(alreadyInclude => !!alreadyInclude)[0];
    }

    const confirmVariants = async (e) => {
        try {
            e.preventDefault();

            setIsLoading(true);

            const idAlreadyAdded = isVariantAlreadyAdded(selectedProducts, selectedVariants);

            if (idAlreadyAdded) {
                return Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: isGlobalProducts
                        ? `A  variação ${idAlreadyAdded.name} - ${idAlreadyAdded.id} já foi excluido da regra, por favor desmarque-a!!`
                        : `Já existe uma regra com a variação ${idAlreadyAdded.name} - ${idAlreadyAdded.id}, por favor desmarque-a!!`
                });
            }

            if (isGlobalProducts) {
                await createRuleForGlobalProducts(selectedVariants, product);
            } else {
                await createRuleForSpecifcProducts(selectedVariants, product);
            }

            toggle();
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Modal
            isOpen={isOpen}
            toggle={toggle}
            centered
            className={`modal-container-${!isGlobalProducts ? 'lg' : 'md'}`}
        >
            <ModalHeader className='modal-header'>
                <span className="d-flex justify-content-between align-items-center mb-0 h4">
                    Selecione as variações do (a) {product.name.pt}
                    <IconBase
                        path={mdiClose}
                        size={0.8}
                        className='close-icon cursor-pointer ml-2'
                        onClick={toggle}
                    />
                </span>
            </ModalHeader>
            <ModalBody>
                {
                    !isGlobalProducts &&
                    <p className="info-card d-flex align-items-center">
                        <IconBase className="mr-2" size={0.8} path={mdiInformationOutline} />
                        Coloque 0 para não ter um mínimo ou um máximo de produtos no carrinho
                    </p>
                }
                <Table striped responsive>
                    <thead>
                        <tr>
                            <th>Nome</th>
                            <th>Identificador</th>
                            <th>Estoque</th>
                            <th>Preço</th>
                            <th>Selecionar</th>
                            {
                                !isGlobalProducts && (
                                    <>
                                        <th>Mínimo</th>
                                        <th>Máximo</th>
                                    </>
                                )
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {product?.variants.map(({ id, values, stock, sku, price }) => (
                            <tr key={id}>
                                <td className="TableTd">{values[0]?.pt || "-"}</td>
                                <td className="TableTd">{sku || '-'}</td>
                                <td className="TableTd">{stock}</td>
                                <td className="TableTd">{price}</td>
                                <td className="TableTd">
                                    <input
                                        className="cursor-pointer ml-4"
                                        type='checkbox'
                                        name='variant'
                                        id={id}
                                        onChange={(e) => selectNewVariation(e, id)}
                                        defaultChecked
                                    />
                                </td>
                                {
                                    !isGlobalProducts && (
                                        <>
                                            <td className="TableTd">
                                                <Input
                                                    type='number'
                                                    id='min'
                                                    name='min'
                                                    defaultValue={0}
                                                    onChange={(e) => addMin(e, id)}
                                                    placeholder="Mínimo de items"
                                                    className='input-modal-variants'
                                                    disabled={!ifAlreadySelected(id)}
                                                />
                                            </td>
                                            <td className="TableTd">
                                                <Input
                                                    type='number'
                                                    id='max'
                                                    name='max'
                                                    defaultValue={0}
                                                    onChange={(e) => addMax(e, id)}
                                                    placeholder="Máximo de items"
                                                    className='input-modal-variants'
                                                    disabled={!ifAlreadySelected(id)}
                                                />
                                            </td>
                                        </>
                                    )
                                }
                            </tr>
                        ))}
                    </tbody>
                </Table>
                <Row>
                    <Col className='d-flex justify-content-end'>
                        <Button type='button' onClick={confirmVariants}>
                            Confirmar variações
                        </Button>
                    </Col>
                </Row>
            </ModalBody>
        </Modal>
    )
}

export default ModalSelectVariants;